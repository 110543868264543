import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import FilterBaladiaReports from "./FilterBaladiaReports"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { usePrevious } from "common/CustomHooks"
import NOData from "common/NOData"
import Viewtooltip from "./Viewtooltip"
import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ToastComponent from "common/ToastComponent"

const BaladiaReports = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const [ishover, setIshover] = useState(false)
  const toggleIsHover = () => {
    setIshover(!ishover)
  }
  const [selectedID, setSelectedID] = useState()

  const [reportData, setReportData] = useState([])
  const [editing, setEditing] = useState(false)

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    ReferenceNo: "",
    startDate: "",
    endDate: "",
    RequestID: "",
    Status: "",
    Download: false,
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  const prevFilteredValues = usePrevious(filteredValues)
  useEffect(() => {
    if (JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)) {
      setPage(1)
    }

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Page: page,
      ReferenceNo: filteredValues.ReferenceNo,
      RequestID: filteredValues.RequestID,
      Status: filteredValues.Status,
      StartDate: filteredValues.startDate,
      EndDate: filteredValues.endDate,
      Downloads: filteredValues.AllReports ? "1" : "0",
    }

    axios
      .post("https://rd0.cpvarabia.com/api/balady/BaladyList.php", {
        ...formData,
      })
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        array.splice(-4)
        console.log("rsds", array)

        setReportData(array)
        setLoading(false)
      })
      .catch(error => {
        console.log(error)
      })
  }, [editing, page, filteredValues])

  // ******************* selected BaladiaReports ************************
  const [selectedBaladiaReports, setSelectedBaladiaReports] = useState([])
  const [selectedBaladiaReportsStatus, setSelectedBaladiaReportsStatus] =
    useState("")
  // console.log("selectedBaladiaReports==========>", selectedBaladiaReports)
  // console.log("selectedBaladiaReportsStatus==========>", selectedBaladiaReportsStatus)

  const selectedBaladiaReportsHandler = id => {
    if (selectedBaladiaReports.includes(id)) {
      setSelectedBaladiaReports(
        selectedBaladiaReports.filter(item => item !== id)
      )
    } else {
      setSelectedBaladiaReports([...selectedBaladiaReports, id])
    }
  }

  const errors = { selectedBaladiaReports: "", selectedStatus: "" }
  selectedBaladiaReports.map((item, i) => {
    const report = reportData.filter(report => report.PStageID === item)[0]
    // console.log("report", report)
    if (report.Tnum * 1 > 0)
      errors.selectedBaladiaReports =
        "some selected BaladiaReports still have tickets!!"
  })

  if (selectedBaladiaReports.length > 0 && !selectedBaladiaReportsStatus)
    errors.selectedStatus = "required status"

  const downloadFunction = item => {
    let data = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),

      BaladyID: item.BaladyID,
    }
    axios
      .post("https://rd0.cpvarabia.com/api/balady/UpdateBalady.php", data)
      .then(res => console.log("res", res))
      .catch(err => console.log("err", err))
  }

  const handleCopy = text => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!", {
        autoClose: 500,
      })
    })
  }

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  if (
    userPermissions.R3.P !== "1" &&
    userPermissions.R3.P !== "2" &&
    userPermissions.R3.P !== "3" &&
    userPermissions.R3.P !== "4" &&
    userPermissions.R3.G !== "1"
  )
    return (
      <div className="page-content">
        <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
          You are not authenticated!!
        </h3>
      </div>
    )
  else {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Baladi Reports"
              breadcrumbItem="Baladi Reports"
            />

            {/* ********************** Filter Section ***************************** */}
            <div className="d-flex col-12">
              <FilterBaladiaReports
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
            </div>

            <Row>
              <Col lg="12">
                <div className="">
                  <div className="table-responsive">
                    {/******************* table section**************************/}
                    <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "20px" }}>
                            #
                          </th>

                          <th scope="col">Request No.</th>
                          <th scope="col">Date</th>
                          <th scope="col">observations</th>
                          <th scope="col">Result</th>
                          <th scope="col"></th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      {/***********table body *****************/}
                      <tbody>
                        {reportData.map((item, key) => (
                          <tr
                            key={key}
                            className={
                              item.Download === "1" && "strikethrough-row"
                            }
                          >
                            <td>
                              <input
                                disabled={
                                  item.Tnum * 1 > 0 ||
                                  item.Status === "Approved"
                                }
                                value={item.PStageID}
                                type="checkbox"
                                onClick={e => {
                                  selectedBaladiaReportsHandler(e.target.value)
                                }}
                                className="form-check-input  p-1 border-primary"
                              />
                            </td>

                            <td id={"Tooltip-" + item.RequestID}>
                              {item.RequestID}
                              {/* {item.ReferenceNo && ( */}
                              <UncontrolledTooltip
                                // autohide={true}
                                placement="bottom"
                                target={"Tooltip-" + item.RequestID}
                              >
                                ReferenceNo : {item.ReferenceNo}
                              </UncontrolledTooltip>
                              {/* ) */}
                            </td>

                            <td>{item.CreatedAt}</td>
                            <td className="relative ">
                              {item.observation ? (
                                <span>
                                  {" "}
                                  {item.observation?.slice(0, 15)}
                                  {item.observation?.length > 15 ? "..." : ""}
                                </span>
                              ) : (
                                "لا يوجد بيانات"
                              )}
                            </td>

                            <td>
                              <span
                                style={{ fontSize: "14px" }}
                                className={
                                  item.Status == "مقبولة"
                                    ? "badge bg-success"
                                    : "badge bg-danger"
                                }
                              >
                                {item.Status}
                              </span>
                            </td>
                            <td>
                              <i
                                onClick={() => {
                                  setSelectedID(item.BaladyID)
                                  toggleIsHover()
                                }}
                                className="fas fa-eye ms-2"
                                style={{
                                  color: "#6a6aee",
                                  fontSize: "15px",
                                  cursor: "pointer",
                                }}
                              ></i>
                              <i
                                onClick={() => handleCopy(item.Decision)}
                                className="fas fa-copy ms-2"
                                style={{
                                  color: "#6a6aee",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                              ></i>
                              {ishover && item.BaladyID === selectedID && (
                                <Viewtooltip
                                  requestNo={item.RequestID}
                                  referenceNo={item.ReferenceNo}
                                  observations={item.observation}
                                  toggle={toggleIsHover}
                                  isOpen={ishover}
                                />
                              )}
                            </td>

                            <td>
                              {item.File && (
                                <UncontrolledDropdown className="btn-group bg-primary">
                                  <a
                                    download
                                    href={item.File}
                                    className="btn btn-primary btn-sm"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button
                                      onClick={() => {
                                        item.Download === "0" &&
                                          downloadFunction(item)
                                      }}
                                      className="btn btn-primary btn-sm dropdown-toggle"
                                    >
                                      Download
                                    </button>
                                  </a>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
            <NOData data={reportData} loading={loading} />

            {reportData.length > 1 && (
              <Row>
                <PaginationComponent
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                  setTotalPages={setTotalPages}
                />
              </Row>
            )}
          </Container>
          <ToastContainer />
        </div>
      </React.Fragment>
    )
  }
}

export default BaladiaReports
