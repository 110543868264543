import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import {
  Col,
  Container,
  Row,
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import ApproveMainModal from "../MangedApprove/ApproveMainModal"
import ImageFullOptions from "./ImageFullOptions"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import ViewRD5 from "./ViewRD5"
import SendRD5 from "./SendRD5"
import FilterRD5 from "./FilterRD5"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { usePrevious } from "common/CustomHooks"
import NOData from "common/NOData"

const RD5Reports = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  //Manged Approved
  const [menu, setMenu] = useState(false)
  const [approvedModal, setapprovedModal] = useState(false)
  const toggleApproved = () => setapprovedModal(!approvedModal)
  const [checked, setChecked] = useState(false)
  const handleChange = () => {
    setChecked(!checked)
  }

  const [reports, setReports] = useState([])
  const [editing, setEditing] = useState(false)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)
  const viewToggle = () => {
    setViewModal(!viewModal)
  }

  //******************************** send Toggle********************************//
  const [sendModal, setSendModal] = useState(false)
  const sendToggle = () => {
    setSendModal(!sendModal)
  }

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  // ************************** Filter section *****************************

  const initialFilteredValues = {
    RD5ID: [],
    ticketCode: [],
    referenceNo: [],
    creator: [],
    status: [],
    type: [],
    startDate: "",
    endDate: "",
  }
  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)
  // console.log("filteredValues=========>", filteredValues)

  // ********************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  const prevFilteredValues = usePrevious(filteredValues)

  // ****************** Image Full Options ******************
    const [viewImageFullOptions, setViewImageFullOptions] = useState(false)

  useEffect(
    () => {
      if (
        JSON.stringify(prevFilteredValues) !== JSON.stringify(filteredValues)
      ) {
        setPage(1)
      }
      const formData = {
        PerToken: localStorage.getItem("token"),
        PerUserID: localStorage.getItem("id"),
        PerRoleID: localStorage.getItem("userRoleID"),
        Page: page,
        RD5ID: filteredValues.RD5ID.join(","),
        Code: filteredValues.ticketCode.join(","),
        ReferenceNo: filteredValues.referenceNo.join(","),
        Type: filteredValues.type.map(item => item.value).join(","),
        // Creators: filteredValues.creator.map(item => item.value).join(","),
        Status: filteredValues.status.map(item => item.value).join(","),
        StartDate: filteredValues.startDate,
        EndDate: filteredValues.endDate || filteredValues.startDate,
      }

      // if (
      //   userPermissions.R19.P === "1" ||
      //   userPermissions.R19.P === "2" ||
      //   userPermissions.R19.P === "3" ||
      //   userPermissions.R19.P === "4" ||
      //   userPermissions.R19.G === "1" ||
      //   userPermissions.R3.G === "1" ||
      //   userPermissions.R4.G === "1"
      // ) {
      //   axios
      //     .post("https://rd0.cpvarabia.com/api/RD5View", {
      //       ...formData,
      //       Creators: filteredValues.creator.map(item => item.value).join(","),
      //     })
      //     .then(res => {
      //       if (
      //         res.data.error === true &&
      //         res.data.message === "Access denied!"
      //       ) {
      //         setAuth(true)
      //         setTimeout(() => {
      //           history.push("/logout")
      //           setTimeout(() => {
      //             history.push("/login")
      //           }, 1000)
      //         }, 4000)
      //       }

      //       // console.log("res", res)
      //       setTotalPages(res.data.TotalPages)
      //       let array = Object.values(res.data)
      //       array.splice(-4)
      //       // console.log("array", array)
      //       setReports(array)
      //     })
      //     .catch(err => console.log(err))
      // } else {
      axios
        .post("https://rd0.cpvarabia.com/api/RD5View", {
          ...formData,
          Creators: filteredValues.creator.map(item => item.value).join(","),
        })
        .then(res => {
          if (
            res.data.error === true &&
            res.data.message === "Access denied!"
          ) {
            setAuth(true)
            setTimeout(() => {
              history.push("/logout")
              setTimeout(() => {
                history.push("/login")
              }, 1000)
            }, 4000)
          }

          // console.log("res", res)
          setTotalPages(res.data.TotalPages)
          let array = Object.values(res.data)
          array.splice(-4)
          // console.log("array", array)
          setReports(array)
          setLoading(false)
        })
        .catch(err => console.log(err))
    },
    // }
    [editing, filteredValues, page]
  )

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  // not allowed views
  // if (
  //   userPermissions.R3.P !== "1" &&
  //   userPermissions.R3.P !== "2" &&
  //   userPermissions.R3.P !== "3" &&
  //   userPermissions.R3.P !== "4" &&
  //   userPermissions.R3.G !== "1" &&
  //   userPermissions.R4.P !== "1" &&
  //   userPermissions.R4.P !== "2" &&
  //   userPermissions.R4.P !== "3" &&
  //   userPermissions.R4.P !== "4" &&
  //   userPermissions.R4.G !== "1" &&
  //   userPermissions.R19.P !== "1" &&
  //   userPermissions.R19.P !== "2" &&
  //   userPermissions.R19.P !== "3" &&
  //   userPermissions.R19.P !== "4" &&
  //   userPermissions.R19.G !== "1"
  // )
  //   return (
  //     <div className="page-content">
  //       <h3 className="d-flex justify-content-center p-3 text-light bg-danger">
  //         You are not authenticated!!
  //       </h3>
  //     </div>
  //   )
  // else {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="RD-5 Reports" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex col-12">
            <Col sm={11}>
              <FilterRD5
                filteredValues={filteredValues}
                setFilteredValues={setFilteredValues}
                setAuth={setAuth}
              />
            </Col>
            {/* Manged Approve */}
            {/* <Dropdown
              isOpen={menu}
              style={{ height: "40px" }}
              className="d-inline-block d-flex ms-auto col-1 dropdown-toggle justify-content-end  me-3"
            >
              <DropdownToggle
                className="btn header-item  "
                id="page-header-user-dropdown"
                tag="button"
                style={{ height: "15px" }}
              >
                <i className="bx bx-cog h3" onClick={() => setMenu(!menu)} />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem tag="a" href="#" onClick={toggleApproved}>
                  Auto Approved
                </DropdownItem>
              </DropdownMenu>
            </Dropdown> */}
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "20px" }}>
                          #
                        </th>
                        <th scope="col">RD-5 ID</th>
                        <th scope="col">Ticket Code</th>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Created By</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Creation Date</th>
                        <th scope="col">Issued Date</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {reports.map((item, key) => (
                        <tr key={key}>
                          <td>
                            <input
                              type="checkbox"
                              className="form-check-input p-1 border-primary"
                            />
                          </td>

                          <td>{item.ReportID}</td>
                          <td>{item.TicketCode}</td>
                          <td>{item.ReferenceNo}</td>
                          <td>{item.CreatedBy}</td>
                          <td>
                            <span
                              className={
                                item.Status === "Active"
                                  ? "badge bg-danger"
                                  : item.Status === "Inactive"
                                  ? "badge bg-success"
                                  : "badge bg-warning"
                              }
                            >
                              {item.Status}
                            </span>
                          </td>
                          <td>{item.Type}</td>
                          <td>{item.CreatedAt}</td>
                          <td>{item.SentDate || "-"}</td>

                          <td>
                            <UncontrolledDropdown className="btn-group bg-primary">
                              <button
                                onClick={() => {
                                  setSelectedItem(item)
                                  viewToggle()
                                }}
                                className="btn btn-primary btn-sm dropdown-toggle"
                              >
                                View
                              </button>
                              <DropdownToggle
                                tag="a"
                                to="#"
                                className="card-drop"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                {/* {(!item.SentDate &&
                                    (userPermissions.R19.P === "1" ||
                                      userPermissions.R19.P === "2" ||
                                      userPermissions.R19.P === "3" ||
                                      userPermissions.R19.P === "4" ||
                                      userPermissions.R19.G === "1")) ||
                                    (item.SentDate && (
                                      <DropdownItem
                                        onClick={() => {
                                          setSelectedItem(item)
                                          sendToggle()
                                        }}
                                      >
                                        Send RD5
                                      </DropdownItem>
                                    ))} */}
                                {(item.Status === "ModeratorApproval" ||
                                  item.Status === "Active") &&
                                  item.SentDate && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        sendToggle()
                                      }}
                                    >
                                      Send RD5
                                    </DropdownItem>
                                  )}
                                {(item.Status === "ModeratorApproval" ||
                                  item.Status === "Active") &&
                                  !item.SentDate &&
                                  (userPermissions.R19.P === "1" ||
                                    userPermissions.R19.P === "2" ||
                                    userPermissions.R19.P === "3" ||
                                    userPermissions.R19.P === "4" ||
                                    userPermissions.R19.G === "1") && (
                                    <DropdownItem
                                      onClick={() => {
                                        setSelectedItem(item)
                                        sendToggle()
                                      }}
                                    >
                                      Send RD5
                                    </DropdownItem>
                                  )}
                                {item.Type == "MS" && (
                                  <DropdownItem
                                    onClick={() => {
                                      setSelectedItem(item)
                                      setViewImageFullOptions(true)
                                    }}
                                  >
                                    change image
                                  </DropdownItem>
                                )}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <NOData data={reports} loading={loading} />

          {viewModal && (
            <ViewRD5
              viewToggle={viewToggle}
              sendToggle={sendToggle}
              selectedItem={selectedItem}
            />
          )}

          {sendModal && (
            <SendRD5
              sendToggle={sendToggle}
              selectedItem={selectedItem}
              editing={editing}
              setEditing={setEditing}
              setAuth={setAuth}
            />
          )}

          <ApproveMainModal
            approvedModal={approvedModal}
            toggleApproved={toggleApproved}
            type={5}
          />

          {reports.length > 0 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
          {viewImageFullOptions && (
                  <ImageFullOptions
                    viewImageFullOptions={viewImageFullOptions}
                    toggleImageFullOptions={()=>setViewImageFullOptions(!viewImageFullOptions)}
                    ReportID={selectedItem.ReportID}                    
                    name={`Attached`}
                    target={"Image"}
                    setAuth={setAuth}
                    
                    
                  />
                )}
        </Container>
      </div>
    </React.Fragment>
  )
  // }
}

export default RD5Reports
