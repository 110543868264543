import React, { useEffect, useState } from "react"
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap"
import Select from "react-select"
import axios from "axios"
import { useHistory } from "react-router-dom"

const ViewRD7Report = ({
  viewToggle,
  viewQuotationToggle,
  selectedItem,
  userPermissions,
  setAuth,
  editing,
  setEditing,
  statusOptions,
  IsAreaManager,
}) => {
  const history = useHistory()
  // console.log("IsAreaManager", IsAreaManager)
  const [declaration, setDeclaration] = useState("")
  //************* form control ******/
  const [modal, setmodal] = useState(true)
  const toggle = () => {
    viewToggle()
  }
  const onClose = () => {
    viewToggle()
  }
  console.log("selectedItem", selectedItem);

  // *********************** Fetching user data ******************************** //
  const [users, setUsers] = useState([])
  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      Type: "Design",
    }
    axios
      .post("https://rd0.cpvarabia.com/api/RD7/MenuRD7UsersList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let data = Object.values(res.data)
        data.splice(-1)
        let dataUsers = data.map(user => ({
          value: user.UserID,
          label: `${user.FirstName} ${user.LastName}`,
        }))
        setUsers(dataUsers)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [RD7Status, setRD7Status] = useState(selectedItem.Status)
  const [RD7PaymentStatus, setRD7PaymentStatus] = useState(selectedItem.Paid)
  const [RD0Responsible, setRD0Responsible] = useState("")

  // console.log("RD7Status", RD7Status)
  // console.log("RD7PaymentStatus", RD7PaymentStatus)

  // *********************** Submit handler ******************************** //
  const submitHandler = e => {
    e.preventDefault()

    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      RD7ID: selectedItem.RD7ID,
    }

    if (RD7Status !== selectedItem.Status) formData.Status = RD7Status
    if (RD7PaymentStatus === "1") formData.RD7PaymentStatus = "1"
    if (RD0Responsible) formData.RD0ResponsibleID = RD0Responsible

    console.log("formData", formData)

    axios
      .post("https://rd0.cpvarabia.com/api/RD7/RD7Action.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  const submitEmailHandler = () => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedItem.ProjectID,
    }

    axios
      .post(
        "https://rd0.cpvarabia.com/api/RD7/EmailNotification.php",
        formData
      )
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        setEditing(!editing)
      })
      .catch(err => console.log(err))

    onClose()
  }

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      ProjectID: selectedItem.ProjectID,
    }
    axios
      .post(`https://rd0.cpvarabia.com/api/Declaration.php`, formData)
      .then(res => {
        console.log()
        setDeclaration(res.data.Declaration)
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <Modal isOpen={modal} toggle={toggle} className="modal-lg">
      <ModalHeader>
        View RD7 Report
        <button
          type="button"
          className="btn-close position-absolute end-0 top-0 m-3"
          onClick={onClose}
        />
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={submitHandler}>
          <Table bordered>
            <tbody>
              <tr>
                <th>Reference No</th>
                <td>{selectedItem.ReferenceNo}</td>
                <th>Status</th>
                <td className="d-flex justify-content-between">
                  <Input
                    type="select"
                    name="RD7Status"
                    style={{ maxWidth: "150px" }}
                    onChange={e => {
                      // console.log(e.target.value)
                      setRD7Status(e.target.value)
                    }}
                    value={RD7Status}
                    disabled={
                      userPermissions.R21.P !== "1" &&
                      userPermissions.R21.P !== "2" &&
                      userPermissions.R21.P !== "3" &&
                      userPermissions.R21.P !== "4" &&
                      userPermissions.R21.G !== "1"
                    }
                  >
                    {statusOptions.map((status, i) => (
                      <option
                        key={i}
                        value={status.RD7SID}
                        // selected={selectedItem.Status === status.RD7SID}
                      >
                        {status.Name}
                      </option>
                    ))}
                  </Input>

                  {(userPermissions.R21.P === "1" ||
                    userPermissions.R21.P === "2" ||
                    userPermissions.R21.P === "3" ||
                    userPermissions.R21.P === "4" ||
                    userPermissions.R21.G === "1") && (
                    <Button
                      className="mx-1 bg-danger"
                      onClick={submitEmailHandler}
                      disabled={RD7Status !== selectedItem.Status}
                    >
                      Email
                    </Button>
                  )}
                </td>
              </tr>

              <tr>
                <th>RD7 Responsible</th>
                <td>{selectedItem.CreatedByName}</td>
                <th>RD0 Responsible</th>
                <td>
                  {selectedItem.RD0Responsible ? (
                    selectedItem.RD0Responsible
                  ) : (
                    <Select
                      options={users}
                      name="RD0Responsible"
                      className="react-select-container"
                      onChange={e => {
                        // console.log(e)
                        setRD0Responsible(e.value)
                      }}
                      classNamePrefix="select"
                      placeholder="RD0 Responsible ..."
                    />
                  )}
                </td>
              </tr>

              <tr>
                <th>RD7 Cost</th>
                <td>{selectedItem.RD7Total}</td>
                <th>RD0 Status</th>
                <td>{selectedItem.RD0Status}</td>
              </tr>

              <tr>
                <th>RD7 Payment Status</th>
                <td>
                  {selectedItem.Paid !== "1" ? (
                    <Input
                      type="select"
                      name="RD7PaymentStatus"
                      style={{ maxWidth: "150px" }}
                      onChange={e => {
                        // console.log(e.target.value)
                        setRD7PaymentStatus(e.target.value)
                      }}
                      disabled={
                        userPermissions.R21.P !== "1" &&
                        userPermissions.R21.P !== "2" &&
                        userPermissions.R21.P !== "3" &&
                        userPermissions.R21.P !== "4" &&
                        userPermissions.R21.G !== "1"
                      }
                    >
                      <option value="0">Not Paid</option>
                      <option value="1">Paid</option>
                    </Input>
                  ) : (
                    <span
                      className={
                        selectedItem.Paid === "1"
                          ? "badge bg-success"
                          : selectedItem.Paid === "0"
                          ? "badge bg-danger"
                          : ""
                      }
                    >
                      {selectedItem.Paid === "1"
                        ? "Paid"
                        : selectedItem.Paid === "0"
                        ? "Not Paid"
                        : ""}
                    </span>
                  )}
                </td>
                <th>TIS Fees Status</th>
                <td>
                  <span
                    className={
                      selectedItem.ProjectPaymentStatus === "1"
                        ? "badge bg-success"
                        : selectedItem.ProjectPaymentStatus === "0"
                        ? "badge bg-danger"
                        : ""
                    }
                  >
                    {selectedItem.ProjectPaymentStatus === "1"
                      ? "Paid"
                      : selectedItem.ProjectPaymentStatus === "0"
                      ? "Not Paid"
                      : ""}
                  </span>
                </td>
              </tr>

              <tr>
                <th>Area Manager</th>
                <td>{selectedItem.AreaManagerName}</td>
              </tr>
            </tbody>
          </Table>

          <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
            <div className="col-8 d-flex justify-content-between">
              {/* <a
                href={`https://inspection.cpvarabia.com/wordlib/vendor/phpoffice/phpword/samples/rd7.php?PStageID=${selectedItem.PStageID}`}
                target="_blank"
                rel="noopener noreferrer"
                download
              >
                <button type="button" className="btn btn-primary btn-label">
                  Export Word
                  <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                </button>
              </a> */}

              {!selectedItem.PStageID ? (
                <button
                  type="button"
                  className="btn btn-primary btn-label"
                  disabled
                >
                  Export Word
                  <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                </button>
              ) : (
                <a
                 // href={`https://inspection.cpvarabia.com/wordlib/vendor/phpoffice/phpword/samples/rd7.php?PStageID=${selectedItem.PStageID}`}
                 href={selectedItem.Link}
                  target="_blank"
                  rel="noopener noreferrer"
                  download
                >
                  <button type="button" className="btn btn-primary btn-label">
                    Export Word
                    <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                  </button>
                </a>
              )}

              <Button
                className="bg-primary"
                onClick={() => {
                  onClose()
                  viewQuotationToggle()
                }}
                disabled={
                  userPermissions.R21.P !== "1" &&
                  userPermissions.R21.P !== "2" &&
                  userPermissions.R21.P !== "3" &&
                  userPermissions.R21.P !== "4" &&
                  userPermissions.R21.G !== "1"
                }
              >
                View Quotation
              </Button>

              <Button
                className="bg-primary"
                disabled={selectedItem.Paid !== "1"}
                onClick={() => {
                  console.log();
                  
                  declaration
                    ? history.push(
                        `/rd-7/${selectedItem.ProjectID}/${selectedItem.RD7ID}`,
                        {
                          PStageID: selectedItem.PStageID,
                        }
                      )
                    : history.push(`/PrivacyATerms/${selectedItem.ProjectID}`, {
                        data: selectedItem,
                        from: "RD7",
                        to: `/rd-7/${selectedItem.ProjectID}/${selectedItem.RD7ID}`,
                      })
                }}
              >
                OverView RD7
              </Button>

              <Button
                className="bg-primary"
                onClick={() => {
                  history.push(`/rd7-review/${selectedItem.PStageID}`, {
                    IsAreaManager,
                    selectedItem,
                  })
                }}
                disabled={
                  !selectedItem.PStageID ||
                  (userPermissions.R21.P !== "1" &&
                    userPermissions.R21.P !== "2" &&
                    userPermissions.R21.P !== "3" &&
                    userPermissions.R21.P !== "4" &&
                    userPermissions.R21.G !== "1" &&
                    IsAreaManager !== true) ||
                  !selectedItem.PStageID
                }
              >
                Review RD7
              </Button>
            </div>

            <Button
              type="submit"
              className="bg-primary"
              disabled={
                RD7Status === selectedItem.Status &&
                RD7PaymentStatus !== "1" &&
                !RD0Responsible
              }
            >
              Submit
            </Button>
          </FormGroup>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default ViewRD7Report
