import axios from "axios"
import { Formik } from "formik"
import React, { useEffect, useState } from "react"
import { Tab, Tabs } from "react-bootstrap"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap"
import UpdateModal from "./UpdateModal"

const ViewVisits = ({
  toggle,
  isOpen,
  selectedItem,
  userPermissions,
  setAuth,
  syncDate,
  users,
  stageList,
}) => {
  const onClose = () => {
    toggle()
  }


  const history = useHistory()

  const [RD3HistoryList, setRD3HistoryList] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }

    axios
      .post("https://rd0.cpvarabia.com/api/Visits/VisitHistory.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        // console.log(res.data)
        let array = Object.values(res.data)
        array.splice(-1)
        setRD3HistoryList(array)
      })
      .catch(err => console.log(err))
  }, [])

  const [update, setUpdate] = useState(false)

  return (
    <div>
      <Modal className="modal-lg" isOpen={isOpen} toggle={toggle}>
        <ModalHeader>
          View Visit
          <button
            type="button"
            className="btn-close position-absolute end-0 top-0 m-3"
            onClick={onClose}
          />
        </ModalHeader>
        <ModalBody>
          <Tabs className="mb-5" justify fill>
            <Tab eventKey="report" title="RD-3 Report">
              <Table bordered>
                <tbody>
                  <tr>
                    <th>Reference No</th>
                    <td>{selectedItem.ReferenceNo}</td>
                    <th>Inspector</th>
                    <td>{selectedItem.InspectorName}</td>
                  </tr>

                  <tr>
                    <th>Date</th>
                    <td>{selectedItem.CreatedAt.split(" ")[0]}</td>
                    <th>Status</th>
                    <td>{selectedItem.Status}</td>
                  </tr>
                </tbody>
              </Table>
              {selectedItem.HoldNote && (
                <FormGroup className="col-8 ms-2 d-flex align-items-center">
                  <Label className="col-2" for="comment">
                    Hold Note :{" "}
                  </Label>
                  <Input
                    type="textarea"
                    id="comment"
                    name="comment"
                    disabled={true}
                    value={selectedItem.HoldNote}
                  />
                </FormGroup>
              )}

              <FormGroup className="d-flex ms-auto  mt-5 justify-content-between">
                <div className="col-5 d-flex  justify-content-around">
                  {
                    <div>
                      <a
                        onClick={e =>
                          selectedItem.Report === false && e.preventDefault()
                        }
                        href={selectedItem.Report ? selectedItem.Report : "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={selectedItem.Report}
                      >
                        <button
                          type="button"
                          className="btn btn-primary btn-label"
                          disabled={!selectedItem.Report}
                        >
                          Export Visit
                          <i className="mdi mdi-file-word-outline ms-1 label-icon"></i>
                        </button>
                      </a>
                    </div>
                  }

                  <Button
                    className="bg-primary ms-2"
                    onClick={() => setUpdate(true)}
                  >
                    Update
                  </Button>
                </div>

                {
                  <Button
                    onClick={toggle}
                    type="submit"
                    className="bg-secondary"
                  >
                    Close
                  </Button>
                }
              </FormGroup>
            </Tab>

            <Tab eventKey="history" title="RD3 History">
              <div style={{ maxHeight: "400px", overflowY: "scroll" }}>
                <Table className="text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Updater</th>
                      <th>Action</th>
                      <th>Description</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RD3HistoryList.map((item, i) => (
                      <tr key={i}>
                        <th scope="row">{i + 1}</th>
                        <td>{item.UpdaterName}</td>
                        <td>{item.Action || "-"}</td>
                        <td>{item.Description || "-"}</td>
                        <td>{item.CreatedAt}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </ModalBody>
      </Modal>
      {update && (
        <UpdateModal
          mode="edit"
          isOpen={update}
          toggle={() => setUpdate(!update)}
          selectedItem={selectedItem}
          users={users}
          stageList={stageList}
          syncDate={syncDate}
        />
      )}
    </div>
  )
}

export default ViewVisits
