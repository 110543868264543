import React from "react";
import {
  FaFileAlt,
  FaFilePdf,
  FaFileWord,
  FaFileExcel,
  FaFilePowerpoint,
  FaFileArchive,
  FaFileImage,
  FaFileVideo,
  FaFileAudio,
} from "react-icons/fa";

const FilePreview = ({ data }) => {
  
  const getFileType = (file) => {
    console.log("file", file);
    
    const extension = file.name?file.name.split(".").pop().toLowerCase(): file.split(".").pop().toLowerCase(); // Get the file extension
    switch (extension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
      case "bmp":
      case "webp":
        return "image";
      case "mp4":
      case "webm":
      case "ogg":
        return "video";
      case "mp3":
      case "wav":
      case "flac":
        return "audio";
      case "pdf":
        return "pdf";
      case "doc":
      case "docx":
        return "word";
      case "xls":
      case "xlsx":
        return "excel";
      case "ppt":
      case "pptx":
        return "powerpoint";
      case "zip":
      case "rar":
      case "7z":
        return "archive";
      default:
        return "file";
    }
  };

  const fileType = getFileType(data);
  console.log("fileType", fileType);
  return (
    <div className="file-preview">
      {fileType === "image" ? (
        <img
          style={{ width: "200x", height: "250px" }}
          data-dz-thumbnail=""
          className="img-fluid rounded d-block"
          src={
            typeof data === "object" && data instanceof Blob
              ? URL.createObjectURL(data)
              : data
          }
          alt={data.name}
        />
      ) : (
        <div className="file-icon">
          {fileType === "pdf" && <FaFilePdf size={205} />}
          {fileType === "word" && <FaFileWord size={205} />}
          {fileType === "excel" && <FaFileExcel size={205} />}
          {fileType === "powerpoint" && <FaFilePowerpoint size={205} />}
          {fileType === "archive" && <FaFileArchive size={205} />}
          {fileType === "video" && <FaFileVideo size={205} />}
          {fileType === "audio" && <FaFileAudio size={205} />}
          {fileType === "file" && <FaFileAlt size={205} />}
          <p className="file-name text-center mt-2">{data.name}</p>
       
        </div>
      )}
    </div>
  );
};

export default FilePreview;
