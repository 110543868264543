import React from "react"
import {
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

function Viewtooltip({ requestNo, referenceNo, observations, toggle }) {
  return (
    <Container
      className="p-3 border border-4"
      style={{
        position: "absolute",
        zIndex: "400",
        width: "50%",
        backgroundColor: "white",
        right: "100px",
      }}
    >
      <div className="d-flex m-2 justify-content-start">
        <strong>Technical Observations</strong>
      </div>

      <div className="d-flex justify-content-around p-2">
        <span>
          <strong>Request No :</strong> {requestNo}
        </span>
        <span>
          <strong>Reference No :</strong> {referenceNo}
        </span>
      </div>
      <hr />
      <div
        className="border border-2 p-2"
        style={{ maxHeight: "300px", overflowY: "auto" }}
      >
        <p className="text-right">{observations}</p>
      </div>

      <div className="d-flex justify-content-end mt-2">
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </div>
    </Container>
  )
}

export default Viewtooltip
