import React, { useEffect, useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"
import axios from "axios"
import ProjectHeader from "pages/RD-0/ProjectHeader"
import { useHistory } from "react-router-dom"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"
import RD7 from "./RD7"

const RD7AnswerPage = props => {
  const projectId = props.match.params.ProjectID
  const RD7ID = props.match.params.RD7ID
  const PStageID = props.location.state?.PStageID


  const history = useHistory()

  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )
  // console.log("userPermissions", userPermissions)

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  let SpecialSystem = ""
  let project
  // if (props.location.state) {
  //   localStorage.setItem(
  //     "SpecialSystem",
  //     JSON.stringify(props.location.state.SpecialSystem)
  //   )
  //   localStorage.setItem(
  //     "project",
  //     JSON.stringify(props.location.state.project)
  //   )
  //   SpecialSystem = props.location.state.SpecialSystem
  //   project = props.location.state.project
  // } else {
  //   SpecialSystem = localStorage.getItem("SpecialSystem")
  //   if (SpecialSystem) SpecialSystem = JSON.parse(SpecialSystem)
  //   project = localStorage.getItem("project")
  //   if (project) project = JSON.parse(project)
  // }
  //--
  const [allValues, setAllValues] = useState([])

  const [activeTab, setActiveTab] = useState("1")
  const [data, setData] = useState([])

  useEffect(() => {
    const formData = {
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
      StageID: "12",
      RD7ID: RD7ID,
    }

    axios
      .post("https://rd0.cpvarabia.com/api/Sections/View", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }

        let array = Object.entries(res.data)
        array.splice(-1)
        setData(array)
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  const [submittedReports, setSubmittedReports] = useState([])

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <div className="page-content">
      <ProjectHeader projectID={projectId} />

      <h4 className="mb-3">RD-7 Report Answer</h4>
      <Nav tabs fill>
        {data.map((item, key) => {
          return (
            <NavItem key={key}>
              <NavLink
                className={activeTab == key + 1 ? "active" : ""}
                // onClick={() => setActiveTab((key + 1).toString())}
              >
                {item[1].SectionName}
              </NavLink>
            </NavItem>
          )
        })}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((item, key) => {
          return (
            <TabPane tabId={(key + 1).toString()} key={key}>
              <RD7
                id={item[1].SectionID}
                projectId={projectId}
                SpecialSystem={SpecialSystem}
                submittedReports={submittedReports}
                setSubmittedReports={setSubmittedReports}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                submittedKey={key + 1}
                tabsLength={data.length}
                allValues={allValues}
                setAllValues={setAllValues}
                setAuth={setAuth}
                PStageID={PStageID}
              />
            </TabPane>
          )
        })}
      </TabContent>
    </div>
  )
}

export default RD7AnswerPage
