import axios from "axios"
import { convertPermission } from "permissionUtils"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { Input, Col, Label } from "reactstrap"
import Switch from "react-switch"
import DatePicker from "react-datepicker"

const FilterBaladiaReports = ({
  filteredValues,
  setFilteredValues,
  setAuth,
}) => {
  // **************** Permissions ******************************
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )


    const [dateRange, setDateRange] = useState([null, null])
    const [startDate, endDate] = dateRange
  
    const convertDate = value => {
      let day = value.getDate() + ""
      day = day.length === 1 ? `0${day}` : day
      let month = value.getMonth() + 1 + ""
      month = month.length === 1 ? `0${month}` : month
      let year = value.getFullYear()
      return `${year}-${month}-${day}` || null
    }

  const filteredFeatures = ["Request No.", "Date", "ReferenceNo", "Result"]
  const ResultOptions = ["مقبولة", "غير مقبولة "]

  return (
    <div className="d-flex justify-content-center col-12">
     
          <div className="d-flex col-2 justify-content-start mb-2 mx-1" >
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type="select"
                  name="Result"
                  onChange={e => {
                    // console.log(e.target.value)
                    setFilteredValues({
                      ...filteredValues,
                      Status: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                >
                  <option selected value="">
                    Result...
                  </option>
                  {ResultOptions.map((Result, i) => (
                    <option key={i} value={i+1}>
                      {Result}
                    </option>
                  ))}
                </Input>
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex col-12">
                <input
                  type="text"
                  placeholder="ReferenceNo..."
                  name="ReferenceNo"
                  onChange={e => {                    
                    setFilteredValues({
                      ...filteredValues,
                      ReferenceNo: e.target.value,
                    })
                  }}
                  className="form-control border-dark"
                  disabled={userPermissions.R3.G !== "1"}
                />
              </div>
            </div>
          </div>
          <div className="d-flex col-2 justify-content-start mb-2 mx-1">
          <div className="search-box me-3 col-12">
            <div className="position-relative mb-2 d-flex form-control border-dark ">
              <i className="bx bx-search-alt search-icon " />

              <DatePicker
                isClearable={true}
                className="border-0"
                selectsRange={true}
                dateFormat="yyyy/MM/dd"
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                  setDateRange(update)
                  setFilteredValues({
                    ...filteredValues,
                    startDate: update[0] ? convertDate(update[0]) : null,
                    endDate: update[1] ? convertDate(update[1]) : null,
                  })
                }}
                // withPortal
                placeholderText="Date ..."
              />
            </div>
          </div>
          </div>
        
          <div  className="d-flex col-2 justify-content-start mb-2 mx-1">
            <div className="search-box me-4 col-12 ">
              <div className="position-relative mb-2 d-flex">
                <Input
                  type={"number"
                  }
                  className="form-control border-dark"
                  placeholder={"Request No."}
                  onChange={e => {
                    

            

                    setFilteredValues({
                      ...filteredValues,
                      RequestID: e.target.value,
                    })
                  }}
                />
                <i className="bx bx-search-alt search-icon " />
              </div>
            </div>
          </div>
        
     

      <Col
        sm={2}
        className="d-flex justify-content-center align-items-center mb-3 "
      >
                <Label
          htmlFor="material-switch"
          className="mt-2 mx-1"
          style={{ fontSize: "14px" }}
        >
          New Reports
        </Label>
   
        <Switch
          onChange={() => {
            setFilteredValues({
              ...filteredValues,
              AllReports: !filteredValues.AllReports,
            })
          }}
          checked={filteredValues.AllReports}
          onColor="#24b571"
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          height={20}
          width={42}
          className="react-switch"
          id="material-switch"
        />
             <Label
          htmlFor="material-switch"
          className="mt-2 mx-1"
          style={{ fontSize: "14px" }}
        >
          All Reports
        </Label>

      </Col>
    </div>
  )
}

export default FilterBaladiaReports
