import React, { useState, useEffect } from "react"
import { Link, useHistory, withRouter } from "react-router-dom"
import { Button, Col, Container, Row, Table } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import PaginationComponent from "common/PaginationComponent"
import { convertPermission } from "permissionUtils"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import VisitsFilter from "./VisitsFilter"
import LoadingInsider from "common/LoadingInsider"
import ViewVisits from "./ViewVisits"
import UpdateModal from "./UpdateModal"

const Visits = () => {
  const history = useHistory()

      // Inspector
      const [users, setUsers] = useState([])
      // Status
      const [stageList, setStageList] = useState([])
      // load the selected  data 
      useEffect(() => {
        const formData = {
          PerToken: localStorage.getItem("token"),
          PerUserID: localStorage.getItem("id"),
          PerRoleID: localStorage.getItem("userRoleID"),
          Catagory: "Inspection",
        }
        axios
          .post("https://rd0.cpvarabia.com/api/MenuUsersView", formData)
          .then(res => {
            if (res.data.error === true && res.data.message === "Access denied!") {
              setAuth(true)
              setTimeout(() => {
                history.push("/logout")
                setTimeout(() => {
                  history.push("/login")
                }, 1000)
              }, 4000)
            }
    
            let data = Object.entries(res.data)
            data.splice(-1)
            let dataUsers = data.map(user => ({
              
              value: user[1].UserID,
              label: `${user[1].FirstName} ${user[1].LastName}`,
            }))
    
            setUsers(dataUsers)
          })
          .catch(error => {
            console.log(error)
          })
    
        axios
          .post(
            "https://rd0.cpvarabia.com/api/inspection/InsStageList.php",
            formData
          )
          .then(res => {
            if (res.data.error === true && res.data.message === "Access denied!") {
              setAuth(true)
              setTimeout(() => {
                history.push("/logout")
                setTimeout(() => {
                  history.push("/login")
                }, 1000)
              }, 4000)
            }
    
            let data = Object.entries(res.data)
            data.splice(-1)
    
            let stages = data.map(stage => ({
              value: stage[1].StageID,
              label: stage[1].StageName,
            }))
            setStageList(stages)
          })
          .catch(err => console.log(err))
      }, [])
  

  // *********Document response*********/
  const [visitsList, setVisitsList] = useState([{}])

  // **************** Authorization ******************************
  const [auth, setAuth] = useState(false)

  // **********pagination *********************
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  //******************************** View Toggle********************************//
  const [selectedItem, setSelectedItem] = useState()

  const [viewModal, setViewModal] = useState(false)

  // ************************** Filter section *****************************
  const initialFilteredValues = {
    reportID: [],
    referenceNo: [],
    inspector: [],
    category: [],
    startDate: "",
    endDate: "",
    inactive: "",
  }

  const [filteredValues, setFilteredValues] = useState(initialFilteredValues)

  const [updateData, setUpdateData] = useState(false)
  const syncDate = () => {
    setUpdateData(!updateData)
  }

  useEffect(() => {
    const formData = {
      ...filteredValues,
      PerToken: localStorage.getItem("token"),
      PerUserID: localStorage.getItem("id"),
      PerRoleID: localStorage.getItem("userRoleID"),
    }
    axios
      .post("https://rd0.cpvarabia.com/api/Visits/VisitList.php", formData)
      .then(res => {
        if (res.data.error === true && res.data.message === "Access denied!") {
          setAuth(true)
          setTimeout(() => {
            history.push("/logout")
            setTimeout(() => {
              history.push("/login")
            }, 1000)
          }, 4000)
        }
        let array = Object.values(res.data)
        setTotalPages(res.data.TotalPages)
        // setCleanReport(res.data.cleanReport)
        array.splice(-6)
        setVisitsList(array)
        // setLoading(false)
      })
  }, [filteredValues,updateData])

  const [addVisit, setAddVisit] = useState(false)

  // Access denied !!
  if (auth) {
    return <UnAuthorizedComponent />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Reports" breadcrumbItem="Visits" />

          {/* ********************** Filter Section ***************************** */}
          <div className="d-flex jsutify-content-center text-center ms-5">
            <VisitsFilter
              filteredValues={filteredValues}
              setFilteredValues={setFilteredValues}
              setAuth={setAuth}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Button onClick={() => setAddVisit(true)}>Add Visit</Button>
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive">
                  {/******************* table section**************************/}
                  <Table className="project-list-table table-nowrap text-center align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Reference No.</th>
                        <th scope="col">Date</th>
                        <th scope="col">Stage</th>
                        <th scope="col">Inspector</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    {/***********table body *****************/}
                    <tbody>
                      {visitsList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.ReferenceNo}</td>
                          <td>{item.CreatedAt}</td>
                          <td>{item.StageName}</td>
                          <td>{item.InspectorName}</td>
                          <td>{item.Status}</td>

                          <td>
                            <button
                              onClick={() => {
                                setSelectedItem(item)
                                setViewModal(true)
                              }}
                              className="btn btn-primary btn-sm dropdown-toggle"
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>

                  {visitsList.length === 0 && (
                    <LoadingInsider type="spin" color="gray" />
                  )}
                </div>
              </div>
            </Col>
          </Row>

          {visitsList.length > 1 && (
            <Row>
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPages={totalPages}
                setTotalPages={setTotalPages}
              />
            </Row>
          )}
          {viewModal && (
            <ViewVisits
              toggle={() => setViewModal(!viewModal)}
              isOpen={viewModal}
              selectedItem={selectedItem}
              syncDate={syncDate}
              stageList={stageList}
              users={users}
            />
          )}
          {addVisit && (
            <UpdateModal
              users={users}
              stageList={stageList}
              syncDate={syncDate}
              mode="add"
              isOpen={addVisit}
              toggle={() => setAddVisit(!addVisit)}
              selectedItem={selectedItem}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Visits
